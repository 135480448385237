.party-top-section {
	background: url('https://playace.co/assets/images/become_host_bg1.png');
	background-position: center;
	background-size: 100% 100%;
	background-repeat: no-repeat;
}

:global(p) {
	line-height: 2;
}

.party-bottom-section {
	background: url('https://playace.co/assets/images/become_host_bg2.png');
	background-position: center;
	background-size: 100% 100%;
	background-repeat: no-repeat;
}

.background-grey {
	background: #dbdbdb;
}
