.aboutus_tit {
    text-align: center;
    text-transform: uppercase;
}

.bgBlurCont {
    min-width: 950px;
    max-width: 1000px;
    background: radial-gradient(50% 100.94% at 50% 50%, #131418 0%, rgba(19, 20, 24, 0) 100%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 35px 10px;
    backdrop-filter: blur(5px);
    border-radius: 120px;
    overflow: hidden;
}

.bgBlurCont h1 {
    font-size: 56px;
    line-height: 61.6px;
    text-transform: uppercase;
}

.videoHeroCont {
    margin-top: 4rem;
    position: relative;
    overflow-x: hidden;
    width: 100vw;
}

.foundersCont {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 5rem;
}

.founderContent p {
    font-size: 16px;
    line-height: 19.84px;
    font-family: "Kumbh Sans", sans-serif;
}

.founderContent h4 {
    font-size: 24px;
    line-height: 26.4px;
    margin-bottom: 24px;
}

.founderContent h5 {
    font-size: 20px;
    line-height: 22px;
    color: #9D9D9B;
    margin-bottom: 20px;
}

.founderImg {
    border-radius: 12px;
}

.founderImg img {
    border-radius: 12px;
    width: 250px;
    height: 350px;
}

.founderContent {
    max-width: 380px;
}

.aboutus_tit h3 {
    font-size: 32px;
    line-height: 35.2px;
}

.timeline_cont {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10rem;
    margin-bottom: 8rem;
}

.timeline_content {
    flex: 1;
    position: relative;
}

.timeline_img_cont {
    /* background: #fff; */
    flex: 1;
    /* height: 270px; */
}

.timeline_img_cont img {
    max-width: 304px;
    margin: 0 auto;
    display: block;
}

.timeline {
    position: relative;
    margin-top: 6rem;
    margin-bottom: 12rem;
}

.timeline_img_cont {
    position: relative;
}

.reverse {
    flex-direction: row-reverse;
}

.left::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border: 10px solid #5A5A5D;
    border-radius: 50%;
    right: -97px;
    top: 0;
    /* transform: translateY(-50%); */
}

.timeline::after {
    content: '';
    position: absolute;
    width: 0.5px;
    background-color: white;
    top: 10px;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}

.timeline::before {
    content: '';
    position: absolute;
    width: 0.5px;
    background-color: white;
    bottom: -100px;
    height: 150px;
    left: 50%;
    margin-left: -3px;
}

.timeline_content p {
    font-family: "Kumbh Sans", sans-serif;
    font-size: 16px;
    line-height: 19.84px;
    margin-bottom: 0;
}

.timeline_content h5 {
    text-transform: uppercase;
    font-size: 24px;
    margin-bottom: 28px;
}

.text-end {
    text-align: end;
}

/* .top .left::after {
    top: 20px;
} */

.alignSelf {
    align-self: baseline;
}

.whyPlayAce p {
    font-size: 24px;
    line-height: 29.77px;
    font-family: "Kumbh Sans", sans-serif;
}

.whyPlayAce {
    text-align: center;
}

.bigTit {
    margin-bottom: 2.5rem;
}

.bigTit h3 {
    font-size: 32px;
    line-height: 35.2px;
    text-transform: uppercase;
}

.lastStory {
    position: relative;
    margin-top: 13rem;
}

.lastStory::after {
    content: '';
    position: absolute;
    width: 0.5px;
    background-color: white;
    height: 154px;
    top: -125px;
    left: 50%;
    margin-left: -3px;
}

.changedLives {
    text-align: center;
    padding: 6rem 0;
}

.changedLives p {
    font-size: 24px;
    line-height: 29.77px;
    margin-top: 2rem;
    font-family: "Kumbh Sans", sans-serif;
}

.discoBallCont {
    margin-bottom: 30px;
}

.video-marquee-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.video-marquee-row {
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 200px;
}

.video-marquee {
    display: flex;
    animation: marquee 30s linear infinite;
    position: absolute;
    gap: 20px;
    height: 100%;
}

.video-marquee.right {
    animation: marqueeRight 30s linear infinite;
}

.video-marquee video {
    width: 300px;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.teamMembersCont {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4rem;
    justify-content: center;
    gap: 4rem;
}

.teamMemCard {
    max-width: 225px;
}

.teamMemImgBx img {
    max-width: 100%;
    max-width: 217px;
}

.teamMemCard h3 {
    font-size: 14px;
    line-height: 16.8px;
    text-transform: uppercase;
}

.teamMemCard h4 {
    font-size: 16px;
    line-height: 25.6px;
    color: #9D9D9B;
    font-family: "Kumbh Sans", sans-serif;
}

.teamMemCard {
    text-align: center;
}

.teamMemImgBx {
    margin-bottom: 1.5rem;
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

@keyframes marqueeRight {
    0% {
        transform: translateX(-50%);
    }

    100% {
        transform: translateX(0);
    }
}

@media screen and (max-width: 576px) {

    .bgBlurCont h1 {
        font-size: 32px !important;
        line-height: 41.6px !important;
    }

    .left::after {
        width: 32px;
        height: 32px;
        left: -36px;
        top: 0;
    }

    .timeline::after {
        left: 15px !important;
        margin-left: -3px;
    }

    .timeline_img_cont img {
        max-width: 100%;
        width: 100% !important;
    }

    .timeline_cont {
        align-items: baseline !important;
        gap: 2rem !important;
        padding-left: 2rem;
    }

    .padSticky {
        max-width: 100vw;
    }

    .timeline_cont {
        flex-direction: column;
        gap: 1rem;
    }

    .bgBlurCont {
        min-width: 90% !important;
        border-radius: 12px;
    }

    .aboutus_tit h3 {
        font-size: 24px;
        line-height: 26.2px;
    }

    .timeline::before {
        left: 15px;
    }

    .timeline_content.left.text-end {
        text-align: left;
    }

    .lastStory::after {
        left: 15px;
        top: -140px;
    }


    .foundersCont {

        flex-direction: column;
        text-align: center;
    }

    .whyPlayAce {
        text-align: left;
        padding-left: 2rem;
    }

    .bigTit img {
        width: 90%;
    }

    .changedLives p {
        font-size: 16px;
        line-height: 21.77px;
    }

    .whyPlayAce p {
        font-size: 16px;
        line-height: 24.77px;
    }

    .bigTit h3 {
        font-size: 24px;
        line-height: 27.2px;
        text-transform: uppercase;
    }

}