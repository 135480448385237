.input-wrapper {
  position: relative;
}

:global(.input-label) {
  position: absolute;
  color: grey;
  top: 14px;
  left: 14px;
  font-size: 14px;
  pointer-events: none;
}

.input-style {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0.8rem 0.5rem;
  border-radius: 5px;
  border: 1px solid grey;
  margin: 0.5rem 0;
  &:focus {
    border: none;
    outline: none;
  }
}

:global(.input-control) {
  &:focus {
    outline: none;
    border: 1px solid #ff1844;
  }
}

:global(.input-control + .input-label) {
  transition: 120ms ease-in-out;
  top: -8px;
  left: 12px;
  padding: 0 4px;
  font-size: 12px;
  background: white;
  color: grey;
}

:global(.input-control:focus + .input-label) {
  color: #ff1844;
}
