.masonryGrid {
  display: grid;
  grid-gap: 20px;
  grid-template-areas:
    "verification about parties-info"
    "verification interests spaces-info"
    "verification interests spaces-info"
    "upcoming interests spaces-info";
  grid-template-columns: auto auto auto;
  grid-template-rows: 1fr;
}

.verification {
  grid-area: verification;
}
.about {
  grid-area: about;
}
.parties-info {
  grid-area: parties-info;
}
.upcoming {
  grid-area: upcoming;
}
.interests {
  grid-area: interests;
  div {
    div {
      p {
        white-space: nowrap;
      }
    }
  }
}
.spaces-info {
  grid-area: spaces-info;
}

@media screen and (max-width: 576px) {
  .verification {
    margin-bottom: 2rem;
  }
  .about {
    margin-bottom: 2rem;
  }
  .parties-info {
    margin-bottom: 2rem;
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .upcoming {
    margin-bottom: 2rem;
  }

  .spaces-info {
    margin-bottom: 2rem;
    img {
      display: block;
      margin: 0 auto;
    }
  }

  .interests {
    margin-bottom: 2rem;
    div {
      div {
        p {
          white-space: normal;
        }
      }
    }
  }
}
