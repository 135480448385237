.guest-card-space,
.guest-card-party {
  position: relative;
}

.nametag {
  position: absolute;
  top: 20px;
  left: 0;
  padding: 0.5rem;
  color: white;
  width: 140px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.guestbox {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}

.loading-overlay{
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1450px){
  .guestbox {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
  }
}

@media screen and (max-width: 776px){
  .guestbox {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
  }
}

@media screen and (max-width: 576px){
  .guestbox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
}
