/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'Airbnb Cereal App Book';
font-style: normal;
font-weight: normal;
src: local('Airbnb Cereal App Book'), url('AirbnbCerealBook.woff') format('woff');
}


@font-face {
font-family: 'Airbnb Cereal App Light';
font-style: normal;
font-weight: normal;
src: local('Airbnb Cereal App Light'), url('AirbnbCerealLight.woff') format('woff');
}


@font-face {
font-family: 'Airbnb Cereal App Medium';
font-style: normal;
font-weight: normal;
src: local('Airbnb Cereal App Medium'), url('AirbnbCerealMedium.woff') format('woff');
}


@font-face {
font-family: 'Airbnb Cereal App Bold';
font-style: normal;
font-weight: normal;
src: local('Airbnb Cereal App Bold'), url('AirbnbCerealBold.woff') format('woff');
}


@font-face {
font-family: 'Airbnb Cereal App Extra Bold';
font-style: normal;
font-weight: normal;
src: local('Airbnb Cereal App Extra Bold'), url('AirbnbCerealExtraBold.woff') format('woff');
}


@font-face {
font-family: 'Airbnb Cereal App Black';
font-style: normal;
font-weight: normal;
src: local('Airbnb Cereal App Black'), url('AirbnbCerealBlack.woff') format('woff');
}

.slick-slide img {
    display: block;
    object-fit: contain;
    max-height: 60vh;
}