:global(.otp-field) {
  border: none;
  border-bottom: 1px solid black;
  margin: 0 4px;
  width: 32px;
  text-align: center;
  font-size: 1.25rem;
  color: #333333;
}

:global(.otp-field:focus) {
  outline: none;
  border-bottom: 2px solid black;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}