.flexAlign {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6rem;
}

.scrollBlockCont {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 7rem;
  align-items: center;
  margin-bottom: 5rem;
}

.sBlock {
  /* background: #ccc; */
  min-width: 270px;
  height: 300px;
}


.smallIcon {
  width: 44px;
  height: 44px;
  border-radius: 5px;
  display: grid;
  place-items: center;
  /* margin-bottom: 30px; */
}

.flexyStep {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 30px;
}

.flexyStep p {
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: "Krona One";
  font-size: 16px;
}

.smolIconSparkle svg {
  stroke: #9347FE;
}

.houseParty_leftInfo h4 {
  font-size: 24px;
  text-transform: uppercase;
  line-height: 26.4px;
  margin-bottom: 10px;
}


.houseParty_leftInfo p {
  font-size: 18px;
  line-height: 28.8px;
  font-family: "Kumbh Sans", sans-serif;
}

.kumbh p {
  font-family: "Kumbh Sans", sans-serif;

}

.housePartyInfo h3 {
  font-size: 32px;
  line-height: 40px;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.housePartyInfo p {
  line-height: 19.8px;
  color: #8A8A88;
  font-size: 18px;
  font-family: "Kumbh Sans", sans-serif;
}

.housePartyInfo {
  margin-bottom: 3rem;
}

section.hostLandingHero {
  position: relative;
}



.video-marquee-container-vertical {
  overflow: hidden;
  height: 100vh;
  /* Adjust as needed */
  width: 100%;
  position: relative;
}

.video-marquee-column {
  display: flex;
  flex-direction: column;
  height: 200%;
  /* Adjust as needed to accommodate the vertical scroll */
  position: absolute;
  animation: scrollVertical 20s linear infinite;
}

.video-marquee-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5rem;
}

.rightzero {
  right: 0;
  animation: scrollVerticalReverse 20s linear infinite;
}



.sBlock img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* .spotlight {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 400px;
  background: radial-gradient(circle at 50% 50%, #8338EC 0%, #131418 100%);
  clip-path: polygon(25% 0, 75% 0, 100% 100%, 0% 100%);
  -webkit-clip-path: polygon(25% 0, 75% 0, 100% 100%, 0% 100%);
 
} */

.spotlight {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 725px;
  height: 425px;
  overflow: hidden;
  /* Ensure content outside the shape is hidden */
  pointer-events: none;
  /* Prevent interaction with pseudo-elements */
  filter: blur(45px);
  z-index: 1999;
}

.spotlight::before,
.spotlight::after {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 50% 50%, #8338EC 0%, #131418 100%);
  filter: blur(20px);
  /* Adjust blur radius as needed */
  clip-path: polygon(25% 0, 75% 0, 100% 100%, 0% 100%);
}

.spotlight::before {
  left: 0;
}

.spotlight::after {
  right: 0;
}

.fixed-top {
  z-index: 2001 !important;
}



@keyframes scrollVertical {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-50%);
  }
}

@keyframes scrollVerticalReverse {
  from {
    transform: translateY(-50%);
  }

  to {
    transform: translateY(0);
  }
}

@media screen and (max-width: 576px) {

  .video-marquee-vertical img {
    max-width: 145px;
  }

  .spotlight {
    width: 360px;
    height: 530px;
    filter: blur(70px);
  }


  .sBlock img {
    width: 100%;
    object-fit: cover;
  }

  .scrollBlock_overflow {
    width: 100%;
  }

  .houseParty_leftInfo p {
    font-size: 16px;
    line-height: 23.8px;
  }

  .houseParty_leftInfo h4 {
    font-size: 18px;
    line-height: 22.4px;
  }

  .housePartyInfo h3 {
    font-size: 24px;
    line-height: 32px;
  }

  .scrollBlockCont {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}